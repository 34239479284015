export const APP_BASE_API_URL =
  (process.env.REACT_APP_BASE_PROTOCOL
    ? `${process.env.REACT_APP_BASE_PROTOCOL}://`
    : "") +
  process.env.REACT_APP_BASE_DOMAIN +
  (process.env.REACT_APP_BASE_API_PREFIX
    ? `/${process.env.REACT_APP_BASE_API_PREFIX}`
    : "") +
  "/";

export const APP_BASE_URL =
  (process.env.REACT_APP_BASE_PROTOCOL
    ? `${process.env.REACT_APP_BASE_PROTOCOL}://`
    : "") + process.env.REACT_APP_BASE_DOMAIN;

export const SUPPORT_EMAIL = process.env.REACT_SUPPORT_EMAIL;
